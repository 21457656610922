import { AbilityBuilder } from "@casl/ability";

import * as ROUTES from "../constants/routes";

export default function defineRulesFor(role, options) {
  const { can, rules, cannot } = AbilityBuilder.extract();

  switch (role) {
    case "super-admin":
      can("visit", ROUTES.MAIN);
      can("visit", ROUTES.PROFILE);
      can("visit", ROUTES.SUPER_ADMIN);
      can("visit", ROUTES.SUPER_ADMIN_BRANDING);
      can("visit", ROUTES.SUPER_ADMIN_OAUSERS);
      can("visit", ROUTES.SUPER_ADMIN_CONTRACTS);
      can("visit", ROUTES.SUPER_ADMIN_PROVIDERS);
      can("visit", ROUTES.SUPER_ADMIN_CASELOAD);
      can("visit", ROUTES.SUPER_ADMIN_FEEDBACK);
      can("visit", ROUTES.SUPER_ADMIN_FEEDBACK_SKILLS);
      can("visit", ROUTES.SUPER_ADMIN_CREDITS);
      can("visit", ROUTES.SUPER_ADMIN_COINS);
      can("visit", ROUTES.FAQ);
      break;
    case "flexible":
      can("visit", ROUTES.MAIN);
      cannot("visit", ROUTES.SKILLZMINER);
      if (options && options.candidateCheckbox ) {
        can("visit", ROUTES.CANDIDATES);
      }
      can("visit", ROUTES.DASHBOARDS);
      can("visit", ROUTES.PROFILE);
      can("visit", ROUTES.INSIGHTS);
      if (options && options.caseloadCheckbox ) {
        can("visit", ROUTES.CASELOADS);
      }

      //admin stuff
      can("visit", ROUTES.ADMIN);
      can("visit", ROUTES.ORGANISATION);
      // can("manage", ROUTES.ORGANISATION);
      
      can("visit", ROUTES.QUALIFICATIONS);
      can("manage", ROUTES.QUALIFICATIONS);

      // can("visit", ROUTES.MANAGE_USER);
      // can("visit", ROUTES.NEW_USER);
      if (options && options.adminProviderCheckbox) {
        can("visit", ROUTES.CONTRACTS);
        can("visit", ROUTES.PROVIDERS);
      }
    // can("visit", ROUTES.INTERVENTIONS);
      can("visit", ROUTES.PRINT);
      can("see", "KEYWORKERS");
      can("see", "ORGADMIN_CASELOAD");
      can("set", "role");
      cannot("do", "REASSESSMENT");
      //USER TYPE MANAGEMENT
      // can("manage", "ORGADMINS");
      can("visit", ROUTES.FAQ);
      // eslint-disable-next-line 
      if (options && options.vacancyCheckbox) {
        can("visit", ROUTES.VACANCIES);
        can("do", "ADD_NEW_VACANCY");
        can("do", "MANAGE_VACANCY");
      }
      break;
    case "org-admin":
      can("visit", ROUTES.MAIN);
      cannot("visit", ROUTES.SKILLZMINER);
      can("visit", ROUTES.CANDIDATES);
      can("visit", ROUTES.DASHBOARDS);
      can("visit", ROUTES.PROFILE);
      can("visit", ROUTES.INSIGHTS);
      can("visit", ROUTES.CASELOADS);
      //admin stuff
      can("visit", ROUTES.ADMIN);
      can("visit", ROUTES.ORGANISATION);
      can("manage", ROUTES.ORGANISATION);

      can("visit", ROUTES.QUALIFICATIONS);
      can("manage", ROUTES.QUALIFICATIONS);

      can("visit", ROUTES.BULK_INVITE);
      can("visit", ROUTES.MANAGE_USER);
      can("visit", ROUTES.NEW_USER);
      can("visit", ROUTES.CONTRACTS);
      can("visit", ROUTES.INTERVENTIONS);
      can("visit", ROUTES.PROVIDERS);
      can("visit", ROUTES.PRINT);
      can("see", "KEYWORKERS");
      can("see", "ORGADMIN_CASELOAD");
      can("set", "role");
      cannot("do", "REASSESSMENT");
      //USER TYPE MANAGEMENT
      can("manage", "ORGADMINS");
      can("visit", ROUTES.FAQ);
      can("visit", ROUTES.VACANCIES);
      break;
    case "partner":
      can("visit", ROUTES.MAIN);
      cannot("visit", ROUTES.SKILLZMINER);
      can("visit", ROUTES.CASELOADS);
      can("visit", ROUTES.CANDIDATES);
      can("visit", ROUTES.PRINT);
      can("visit", ROUTES.DASHBOARDS);
      can("visit", ROUTES.PROFILE);
      can("visit", ROUTES.INSIGHTS);
      //admin stuff
      can("visit", ROUTES.ADMIN);
      can("visit", ROUTES.ORGANISATION);
      can("manage", ROUTES.ORGANISATION);

      can("visit", ROUTES.MANAGE_USER);
      can("visit", ROUTES.NEW_USER);
 
      cannot("visit", ROUTES.PROVIDERS);

      can("see", "KEYWORKERS");
      cannot("see", "ORGADMIN_CASELOAD");
      can("set", "role");
      cannot("do", "REASSESSMENT");
      can("visit", ROUTES.FAQ);
      can("manage", "PARTNERS");
      break;
    case "team-leader":
      can("visit", ROUTES.MAIN);
      can("visit", ROUTES.SKILLZMINER);
      can("visit", ROUTES.CANDIDATES);
      can("visit", ROUTES.PRINT);
      can("visit", ROUTES.DASHBOARDS);
      can("visit", ROUTES.PROFILE);
      //admin stuff
      can("visit", ROUTES.ADMIN);
      can("visit", ROUTES.ORGANISATION);

      can("visit", ROUTES.MANAGE_USER);
      can("visit", ROUTES.NEW_USER);
      can("visit", ROUTES.FAQ);
      break;
    case "keyworker":
      can("visit", ROUTES.MAIN);
      can("visit", ROUTES.SKILLZMINER);
      cannot("visit", ROUTES.VACANCIES);
      cannot("visit", `${ROUTES.VACANCIES}/new`);
      can("visit", ROUTES.SKILLZMINER_RESULTS);
      can("visit", ROUTES.INSIGHTS);
      can("visit", ROUTES.CASELOADS);
      can("visit", ROUTES.CANDIDATES);
      can("visit", ROUTES.PRINT);
      can("visit", ROUTES.DASHBOARDS);
      can("visit", ROUTES.PROFILE);

      //ADMIN STUFF
      can("visit", ROUTES.ADMIN);
      //KEYWORKER CAN SEE ORG, CREATE NEW CANDIDATE, AND MANAGE ITS OWN CANDIDATES
      can("visit", ROUTES.ORGANISATION);
      can("visit", ROUTES.NEW_CANDIDATE);
      can("visit", ROUTES.MANAGE_CANDIDATES);

      can("see", "CONTRACT_SWITCHER");
      can("see", "KEYWORKER_CASELOAD");
      can("do", "REASSESSMENT");
      can("visit", ROUTES.FAQ);
      break;
    case "employer-engagement":
      can("visit", ROUTES.MAIN);

      can("visit", ROUTES.VACANCIES);
      can("manage", ROUTES.VACANCIES);
      can("visit", ROUTES.FAQ);
      can("visit", ROUTES.PROFILE);
      can("do", "ADD_NEW_VACANCY");
      can("do", "MANAGE_VACANCY");

      //ADMIN STUFF
      can("visit", ROUTES.ADMIN);
      //CAN SEE ORG, CREATE NEW CANDIDATE, AND MANAGE ITS OWN CANDIDATES
      can("visit", ROUTES.ORGANISATION);

      break;
    case "hr-manager":
      can("visit", ROUTES.MAIN);
      can("visit", ROUTES.NEW_USER);
      can("visit", ROUTES.VACANCIES);
      can("manage", ROUTES.VACANCIES);
      can("visit", ROUTES.CANDIDATES);
      can("see", "LINEMANAGERS");
      can("see", "DEPARTMENTS");
      can("visit", ROUTES.FAQ);
      can("visit", ROUTES.PROFILE);
      can("do", "ADD_NEW_VACANCY");
      can("do", "MANAGE_VACANCY");
      can("set", "role");
      //ADMIN STUFF
      can("visit", ROUTES.ADMIN);
      //CAN SEE ORG, CREATE NEW CANDIDATE, AND MANAGE ITS OWN CANDIDATES
      can("visit", ROUTES.ORGANISATION);
      can("manage", ROUTES.ORGANISATION);
      can("visit", ROUTES.ROLES);
      can("visit", ROUTES.DEPARTMENTS);
      can("visit", ROUTES.DEMOGRAPHICS);
      can("visit", ROUTES.LINEMANAGERS);
      break;
    case "line-manager":
      can("visit", ROUTES.MAIN);
      can("see", "DEPARTMENTS");
      can("do", "REASSESSMENT");
      can("visit", ROUTES.VACANCIES);
      can("manage", ROUTES.VACANCIES);
      can("visit", ROUTES.FAQ);
      can("visit", ROUTES.PROFILE);
      can("visit", ROUTES.CANDIDATES);

      //ADMIN STUFF
      can("visit", ROUTES.ADMIN);
      //CAN SEE ORG, CREATE NEW CANDIDATE, AND MANAGE ITS OWN CANDIDATES
      can("visit", ROUTES.ORGANISATION);
      can("manage", ROUTES.ORGANISATION);
      break;
    default:
      can("visit", ROUTES.MAIN);
      can("visit", ROUTES.FAQ);
  }

  return rules;
}
