import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Slider,
  Button
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  slider_root: {
    color: "#53D28E",
    height: 10,
    marginTop: -7,
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginTop: -14
    }
  },
  slider_root_orange: {
    color: "#FF6A14;",
    height: 10,
    marginTop: -7,
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginTop: -14
    }
  },
  slider_thumb: {
    height: 14,
    width: 14,
    backgroundColor: "#fff",
    border: "3px solid currentColor",
    marginTop: -2,
    marginLeft: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  slider_active: {},
  slider_valueLabel: {
    left: "calc(-50% + 4px)",
  },
  slider_track: {
    height: 10,
    borderRadius: 10,
    paddingRight: 12,
  },
  slider_rail: {
    height: 10,
    borderRadius: 10,
    paddingRight: 12,
    backgroundColor: "#dedede",
  },
  slider_mark: {
    backgroundColor: "#DDE0E5",
    height: 4,
    width: 4,
    borderRadius: 4,
    marginTop: 3,
    marginLeft: 4,
  },
  slider_markActive: {
    backgroundColor: "#53D28E",
  },
  slider_markActiveOrange: {
    backgroundColor: "#FF6A14",
  },
}));

const SkillRow = ({ skill, proficiency, projectedLevel, description, pendingValidationRequest, classes, editSkillLevel, hasUpdates, setHasUpdates, createValidationRequest }) => {
  const proficiencyLevel = {undefined: "not set", 0:"beginner", 1:"beginner", 2:"proficient", 3:"experienced", 4:"expert"}
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <ExpansionPanel
          style={{
            padding: "0",
            boxShadow: "none",
          }}
        >
          <ExpansionPanelSummary
            aria-controls="panel1a-content"
            id="panel2a-header"
            style={{ padding: "0" }}
          >
            <Typography style={{ borderBottom: "1px dashed #000" }}>
              {skill}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: "0" }}>
            {description}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </TableCell>
      <TableCell align="left" style={{width:"50%"}}>
        {"User Expertise Level: " + proficiencyLevel[proficiency]}
        <Slider
          value={proficiency}
          aria-labelledby="discrete-slider"
          /*valueLabelDisplay="auto"*/
          step={1}
          marks
          min={1}
          classes={{
            root: classes.slider_root,
            track: classes.slider_track,
            rail: classes.slider_rail,
            thumb: classes.slider_thumb,
            mark: classes.slider_mark,
            markActive: classes.slider_markActive,
          }}
          max={4}
        />
        {"Your Expertise Evaluation Level: " + proficiencyLevel[projectedLevel]}
        <Slider
          value={projectedLevel ? projectedLevel : 0}
          aria-labelledby="discrete-slider"
          /*valueLabelDisplay="auto"*/
          step={1}
          marks
          min={1}
          classes={{
            root: classes.slider_root_orange,
            track: classes.slider_track,
            rail: classes.slider_rail,
            thumb: classes.slider_thumb,
            mark: classes.slider_mark,
            markActive: classes.slider_markActiveOrange,
          }}
          onChange={(event, newValue) => {
            editSkillLevel(skill, newValue)
            setHasUpdates([...hasUpdates, skill]);
          }}
          max={4}
          
        />
        {/* {str} <LinearProgress variant="determinate" value={proficiency*25} style={{marginTop:10}}/>{" "} */}
      </TableCell>
      <TableCell>
        <Button variant="contained" disabled={pendingValidationRequest} color="primary" size="small" onClick={() => createValidationRequest(skill)}>
          Request Validation
        </Button>
      </TableCell>
    </TableRow>
  );
};

const SkillProficiencyTable = ({ skills, classes, editSkillLevel, hasUpdates, setHasUpdates, createValidationRequest }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Skill</TableCell>
        <TableCell  style={{width:"40%"}} align="right">Expertise</TableCell>
        <TableCell  style={{width:"10%"}} align="right"></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {skills.map((skill) => (
        <SkillRow key={"_" + skill.skill} {...skill} classes={classes} editSkillLevel={editSkillLevel} hasUpdates={hasUpdates} setHasUpdates={setHasUpdates} createValidationRequest={createValidationRequest}/>
      ))}
    </TableBody>
  </Table>
);

const SkillsExpansionPanel = ({ job, defaultExpanded, editSkillLevel, hasUpdates, setHasUpdates, saveSkills, createValidationRequest }) => {
  const [isExpanded, toggleExpansion] = useState(false);
  const [subPannelExpanded, setSubPanelExpanded] = useState("skill");
  const classes = useStyles();

  useEffect(() => {
    toggleExpansion(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <ExpansionPanel
      expanded={isExpanded}
      onChange={(_, isExpanded) => toggleExpansion(isExpanded)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {
          ((job.title.includes("Manually Added") || job.title.includes("Resource Added Skills")) ? (
            <Typography>{job.title}</Typography>
          ) : (
            <Typography>Skill Summary for {job.title}</Typography>
          )
          )}
        {hasUpdates && hasUpdates.length > 0 && (
          <div style={{width:"100%", display:"flex", flexDirection:"row-reverse"}}>
            <Button
                variant="contained" 
                color="primary" 
                onClick={(event) => {
                  event.stopPropagation()
                  saveSkills(hasUpdates);
                  setHasUpdates([])
                }}
              > Save Changes </Button>
          </div>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
        <ExpansionPanel
          expanded={subPannelExpanded === "skill" ? true : false}
          onChange={(_, isExpanded) => subPannelExpanded === "skill" ? setSubPanelExpanded("") : setSubPanelExpanded("skill")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Skills</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SkillProficiencyTable skills={job.skills} classes={classes} editSkillLevel={editSkillLevel} hasUpdates={hasUpdates} setHasUpdates={setHasUpdates} createValidationRequest={createValidationRequest}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={subPannelExpanded === "optional" ? true : false}
          onChange={(_, isExpanded) => subPannelExpanded === "optional" ? setSubPanelExpanded("") : setSubPanelExpanded("optional")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Optional Skills</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SkillProficiencyTable skills={job.optionalSkills} classes={classes} editSkillLevel={editSkillLevel} hasUpdates={hasUpdates} setHasUpdates={setHasUpdates} createValidationRequest={createValidationRequest}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={subPannelExpanded === "deleted" ? true : false}
          onChange={(_, isExpanded) => subPannelExpanded === "deleted" ? setSubPanelExpanded("") : setSubPanelExpanded("deleted")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Removed Skills</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SkillProficiencyTable skills={job.deletedSkills} classes={classes} editSkillLevel={editSkillLevel} hasUpdates={hasUpdates} setHasUpdates={setHasUpdates} createValidationRequest={createValidationRequest}/>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export const SkillsBackpackSummary = ({
  skillsInfo,
  isOnPrintMode,
  groupSkillsBackpack,
  editSkillLevel,
  saveSkills,
  createValidationRequest
}) => {
  const [hasUpdates, setHasUpdates] = useState([]);

  if (!skillsInfo) {
    return null;
  }
  if (groupSkillsBackpack) {
    return (
      <div style={{ paddingLeft: "1em" }}>
        {skillsInfo.map((backpack) => {
          const proficientLevels = Array.from(
            new Set(backpack.skills.map((skill) => skill.proficiency))
          );
          const groupedSkills = proficientLevels.reduce(
            (acl, curr) =>
              Object.assign({}, acl, {
                [curr]: backpack.skills
                  .filter((skill) => skill.proficiency === curr)
                  .map((a) => a.skill),
              }),
            {}
          );
          return (
            <>
              <p>
                Skills Backpack for <b>{backpack.title}</b>
              </p>
              {Object.keys(groupedSkills).map((proficiency) => (
                <p key={proficiency}>
                  {proficiency} in: {groupedSkills[proficiency].join(", ")}
                </p>
              ))}
            </>
          );
        })}
      </div>
    );
  }
  return (
    <>
      {skillsInfo.map((job, i) => (
        <SkillsExpansionPanel
          job={job}
          key={job.title + "_" + i}
          defaultExpanded={isOnPrintMode}
          editSkillLevel={editSkillLevel}
          setHasUpdates={setHasUpdates}
          hasUpdates={hasUpdates}
          saveSkills={saveSkills}
          createValidationRequest={createValidationRequest}
        />
      ))}
    </>
  );
};
