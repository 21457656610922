import {
  Avatar,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TravelIcon from "@material-ui/icons/DirectionsCar";
import EmailIcon from "@material-ui/icons/Email";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PhoneIcon from "@material-ui/icons/Phone";
import SalaryIcon from "@material-ui/icons/Payment";
import PlaceIcon from "@material-ui/icons/Place";
import SchoolIcon from "@material-ui/icons/School";
import ContractIcon from "@material-ui/icons/Timelapse";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { API, Storage } from "aws-amplify";
import _ from "lodash";
import React, { Component, useEffect } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getBestBackpacks,
  getCandidateEngagements,
  getSkillsInfo,
  triggerReassessment,
  addNewSkill,
  editSkillLevel,
  saveSkills,
  createValidationRequest
} from "../../../actions/candidates_actions";
import config from "../../../config";
import { TabControl } from "../../Common/TabControl";
import JobResults from "../../Skillzminer/JobResults";
import { BackpackMatches } from "./BackpackMatches";
import { CandidateEngagement } from "./CandidateEngagement";
import { CandidateSummaryView } from "./CandidateSummaryView";
import { OtherAssessments } from "./OtherAssessments";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  title: {
    marginLeft: 20,
    marginTop: 10,
    fontSize: 28,
  },
  subtitle: {
    //fontSize: 28
    marginLeft: 20,
    marginTop: 8,
  },
  subtitle2: {
    fontSize: 16,
    marginLeft: 0,
    marginTop: 30,
    marginBottom: 10,
  },
  sectionTitle: {
    marginTop: 40,
    marginBottom: 20,
  },
  center: {
    textAlign: "center",
    display: "block",
    paddingTop: 14,
    border: "1px solid #ddd",
  },
  graphBg: {
    backgroundColor: "#fbfbfb",
    border: "1px solid #e8e8e8",
  },
  subheading: {
    marginLeft: 20,
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#444",
  },
  heading: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "10%",
    flexShrink: 0,
  },
  heading1: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: 20,
  },
  subheading1: {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 300,
    color: "#666",
    marginLeft: 10,
    display: "inline-block",
  },

  resultText: {
    fontSize: theme.typography.pxToRem(14),
  },
  resultText1: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  divid: {
    marginBottom: 20,
    marginTop: 20,
  },
  reassess: {
    marginLeft: 15,
    marginTop: 15,
  },
  itemre: {
    marginTop: 10,
    marginBottom: 5,
  },
  header: {
    padding: theme.spacing(3),
  },
  avatarlarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(1),
  },
  light: {
    color: "#666",
  },
  smallicons: {
    color: "#666",
  },
  itemtitle: {
    fontSize: 12,
  },
  prog: {
    marginTop: -12,
  },
  itemgrid: {
    minHeight: 22,
    marginBottom: theme.spacing(1),
  },
  name: {
    maxWidth: 400,
    fontSize: "2rem",
    fontWeight: 500,
  },
  bottomFooterTitle: {
    marginTop:15
  }
});

const OptionsMenu = ({
  candidate,
  portaldetails: { missions, interventions: organizationInterventions },
  auth,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [backdropText, setBackdropText] = React.useState("Generating PDF...");
  return (
    <div>
      <Backdrop
        style={{ zIndex: 9999, color: "#FFF" }}
        open={showBackdrop}
        onClick={() => {
          if (backdropText.toLowerCase().includes("sorry")) {
            setShowBackdrop(false);
          }
        }}
      >
        <CircularProgress color="inherit" />
        <Typography style={{ marginLeft: "1em" }}>{backdropText}</Typography>
      </Backdrop>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={async () => {
            setBackdropText(`Generating PDF...`);
            setShowBackdrop(true);
            let sortedmissions = [];
            _.each(missions, (item) => {
              //GET SCORES

              var newItem = {
                ...item,
              };

              newItem.score =
                candidate.latestAssessmentResult.results[
                  item.dimension
                ].percentage;

              //GET STATUS
              if (candidate.missionsAnalytics) {
                _.each(candidate.missionsAnalytics, (mission) => {
                  if (mission.missionId === item.id) {
                    if (
                      newItem.status !== "Completed" &&
                      mission.event_type === "missionStart"
                    ) {
                      newItem.status = "Started";
                    }

                    if (mission.event_type === "missionAssumedComplete") {
                      newItem.status = "Completed";
                    }
                  }
                });
              }
              sortedmissions.push(newItem);
            });

            sortedmissions = _.sortBy(sortedmissions, ["score"]);
            delete candidate.style_matches;
            delete candidate.interest_matches;

            const payload = {
              sortedmissions,
              ...candidate,
              organizationInterventions,
            };

            try {
              await API.post(
                config.API_NAME,
                "/pdf-service/candidate-pdf",
                {
                  body: { candidate: payload },
                },
                { timeout: 1200000 }
              );
              const s3Url = await Storage.get(
                `candidate-pdf/${candidate.userID}.pdf`,
                {
                  level: "private",
                }
              );
              const blob = await (await fetch(s3Url, { method: "get" })).blob();
              const blobUrl = URL.createObjectURL(blob);
              const anchor = document.createElement("a");
              anchor.href = blobUrl;
              anchor.download = `${candidate.fullName}.pdf`;
              anchor.click();
              setShowBackdrop(false);
            } catch (error) {
              console.log(error);
              setBackdropText(
                `Sorry, couldn't generate PDF at the moment. Please try again`
              );
            }
          }}
        >
          Download PDF
        </MenuItem>
      </Menu>
    </div>
  );
};

export const CandidateHeader = withStyles(styles)(
  ({
    candidate,
    user,
    triggerReassessment,
    classes,
    getBestBackpacks: fetchSb,
    portaldetails,
    auth,
    onMatchClick,
    onJobClick,
    isInternalMobility,
  }) => {
    useEffect(() => {
      async function getBestBackpacks() {
        if (candidate) {
          try {
            await fetchSb();
          } catch (e) {}
        }
      }
      getBestBackpacks();
    }, [candidate, fetchSb]);
    if (!candidate) {
      return null;
    }
    const {
      lastAuthenticatedAt,
      latestAssessmentCreatedAt,
      assessmentVersion,
      occupations,
      desiredOccupations,
      desiredExp,
      //reassessmentTriggeredAt,
      email,
      phoneNumber,
      phonePreferred,
      locationOriginal,
      // bestBackpack,
      // organisation,
      qualifications,
      latestAssessmentResult,
      candidateStatus,
      allow_reverse_mine,
      provider = "",
      internalMobilityLineManager,
      internalMobilityDepartment
    } = candidate;
    const {
      firstName = "",
      lastName = "",
      fullName = "",
    } = user || {}

    const imDepartments = (portaldetails.internalDepartments ? portaldetails.internalDepartments : []).reduce((a, v) => ({ ...a, [v.ID]: v}), {});
    const imLineManagers = (portaldetails.internalLineManagers ? portaldetails.internalLineManagers : []).reduce((a, v) => ({ ...a, [v.userID]: v}), {});
    const imRoles = (portaldetails.internalRoles ? portaldetails.internalRoles : []).reduce((a, v) => ({ ...a, [v.ID]: v}), {});
    console.log(imRoles)

    const { interests = {} } = latestAssessmentResult || {};

    const { name: qualificationsName = "User not sure or missing" } =
      qualifications || {};

    const { salary = [""], contract = [""] } = interests;

    const desiredOccupationList = () => {
      if(isInternalMobility){
        let occupations = [];
        for (const idx in desiredExp) {
          const roleTitle = portaldetails.internalRoles.find((_item) => _item.occupation.includes(desiredOccupations[idx]));
          if(roleTitle){
            occupations.push(roleTitle.roleName)
          }
          else{
            occupations.push(desiredOccupations[idx])
          }
        }  
        return [...new Set(occupations)].join(", ")
      }
      else{
        return candidate.desiredOccupations.join(", ")
      }
    }

    const occupationList = () => {

      let occupations = {};
      for (const idx in desiredExp) {
        if (desiredExp[idx]){
          occupations[desiredOccupations[idx]] = desiredOccupations[idx];
        }
      }
      _.each(candidate.occupations, (o) => {
        occupations[o] = o;
      });
      let _text = "";
      _.each(Object.keys(occupations), (o, index) => {
          if (Object.keys(occupations).length === (index + 1)){
          _text += o;
          } else {
          _text += o +", ";
          }
      });
      return _text;
      
    }
    let showStatus = true
    const usersProvider = portaldetails.providers.find((pv) => pv.sort === provider)
    if (usersProvider && usersProvider.viewPermissions && usersProvider.viewPermissions.additionalPersonalInfoCheckbox){
      showStatus = false
    }

    return (
      <Paper className={classes.header}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs>
            <Grid container className={classes.root} spacing={2}>
              <Grid item>
                <Avatar className={classes.avatarlarge}>
                  {firstName.charAt(0)}
                  {lastName.charAt(0)}
                </Avatar>
              </Grid>
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="h4" className={classes.name}>
                      {fullName}
                      {isInternalMobility && (
                        <IconButton>
                          <Tooltip title="Rising Start">
                            <StarBorderIcon/>
                          </Tooltip>
                        </IconButton>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Badge
                          color="primary"
                          variant="dot"
                          invisible={phonePreferred}
                        >
                          <EmailIcon
                            fontSize="small"
                            className={classes.smallicons}
                          />
                        </Badge>
                      </Grid>
                      <Grid item>
                        {" "}
                        <Typography variant="body2">{email}</Typography>{" "}
                      </Grid>
                    </Grid>
                  </Grid>

                  {phoneNumber && (
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Badge
                            color="primary"
                            variant="dot"
                            invisible={!phonePreferred}
                          >
                            <PhoneIcon
                              fontSize="small"
                              className={classes.smallicons}
                            />
                          </Badge>
                        </Grid>
                        <Grid item>
                          {" "}
                          <Typography variant="body2">
                            {phoneNumber}
                          </Typography>{" "}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <PlaceIcon
                          fontSize="small"
                          className={classes.smallicons}
                        />{" "}
                      </Grid>

                      <Grid item>
                        <Typography variant="body2">{locationOriginal}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <TravelIcon
                          fontSize="small"
                          className={classes.smallicons}
                        />
                      </Grid>

                      <Grid item>
                        {candidate.latestAssessmentResult ? (
                          <Typography variant="body2">
                            Maximum travel to work:{" "}
                            <strong>
                              {
                                candidate.latestAssessmentResult.interests
                                  .locationRadius[0]
                              }
                            </strong>{" "}
                            {config.distanceName}
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <SalaryIcon
                          fontSize="small"
                          className={classes.smallicons}
                        />
                      </Grid>

                      <Grid item>
                        <Typography variant="body2">
                          Salary: {salary[0]}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <SchoolIcon
                          fontSize="small"
                          className={classes.smallicons}
                        />
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="body2"
                          style={{ textTransform: "capitalize" }}
                        >
                          Prior Attainment: {qualificationsName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <ContractIcon
                          fontSize="small"
                          className={classes.smallicons}
                        />
                      </Grid>

                      <Grid item>
                        <Typography variant="body2">
                          Contract Type: {contract[0]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {(showStatus && (
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <EmojiPeopleIcon
                            fontSize="small"
                            className={classes.smallicons}
                          />
                        </Grid>

                        <Grid item>
                          <Typography variant="body2">
                            Status: {(allow_reverse_mine ? "Looking for work" : "Not looking for work") + " (" +candidateStatus +")"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}

                  {(isInternalMobility && (
                    <>
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Typography variant="body2">
                              {"Department: " + (imDepartments[internalMobilityDepartment] ? imDepartments[internalMobilityDepartment].departmentName : "")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Typography variant="body2">
                              {"Line Manager: " + (imLineManagers[internalMobilityLineManager] ? (imLineManagers[internalMobilityLineManager].name + " (" + imLineManagers[internalMobilityLineManager].email + ")"): "")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                  <Grid item>
                    <Typography variant="caption" className={classes.light}>
                      {lastAuthenticatedAt ? (
                        <>
                          Last login:{" "}
                          <Moment utc fromNow>
                            {lastAuthenticatedAt}
                          </Moment>
                        </>
                      ) : (
                        <span>Candidate has not logged in yet.</span>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              {latestAssessmentCreatedAt ? (
                <Grid
                  container
                  direction="row"
                  className={classes.itemgrid}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={5}>
                    <Typography variant="button" className={classes.itemtitle}>
                      Assessment:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <b>{assessmentVersion}</b> assessment completed{" "}
                      <Moment utc fromNow>
                        {candidate.latestAssessmentCreatedAt}
                      </Moment>
                    </Typography>
                  </Grid>
                  {window.location.pathname !== "/print" ? (
                    <Grid item xs style={{ textAlign: "right" }}>
                      <OptionsMenu
                        candidate={candidate}
                        portaldetails={portaldetails}
                        auth={auth}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              ) : (
                ""
              )}

              {desiredOccupations ? (
                <Grid container direction="row" className={classes.itemgrid}>
                  <Grid item xs={5}>
                    <Typography variant="button" className={classes.itemtitle}>
                      {isInternalMobility ? "Current Occupation:" : "Preferred Occupations:"}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="subtitle2">
                      {desiredOccupationList()}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              {occupations ? (
                <Grid container direction="row" className={classes.itemgrid}>
                  <Grid item xs={5}>
                    <Typography variant="button" className={classes.itemtitle}>
                      Past Occupations:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      {occupationList()}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              <BackpackMatches onMatchClick={onMatchClick} onJobClick={onJobClick} />
              {candidate.noJobsNearMe &&
              <Typography className={classes.bottomFooterTitle}>
                Please note: The candidate has no available jobs available based on his current criteria and preferences
              </Typography>
              }
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);

class CandidateSimpleDetails extends Component {
  state = { isOnPrintMode: false };
  componentDidMount() {}

  render() {
    const {
      classes,
      selectedUserData,
      getBestBackpacks,
      getSkillsInfo,
      getCandidateEngagements,
      addNewSkill,
      editSkillLevel,
      saveSkills,
      createValidationRequest,
      isInternalMobility
    } = this.props;
    const { selectedCandidateData } = this.props.candidates;
    const { missions } = this.props.portaldetails;

    var sortedmissions = null;

    if (!selectedCandidateData) {
      return (
        <Grid>
          <Grid container item xs justify="center">
            <CircularProgress size={25} thickness={4} />
          </Grid>
        </Grid>
      );
    } else {
      if (
        selectedCandidateData.latestAssessmentResult &&
        selectedCandidateData.latestAssessmentResult.results
      ) {
        sortedmissions = [];
        _.each(missions, (item) => {
          //GET SCORES

          var newItem = {
            ...item,
          };

          newItem.score =
            selectedCandidateData.latestAssessmentResult.results[
              item.dimension
            ].percentage;

          // GET STATUS
          // OLD
          // if (selectedCandidateData.missionsAnalytics) {
          //   _.each(selectedCandidateData.missionsAnalytics, (mission) => {
          //     if (mission.missionId === item.id) {
          //       if (
          //         newItem.status !== "Completed" &&
          //         mission.event_type === "missionStart"
          //       ) {
          //         newItem.status = "Started";
          //       }

          //       if (mission.event_type === "missionAssumedComplete") {
          //         newItem.status = "Completed";
          //       }
          //     }
          //   });
          // }

          // GET STATUS
          let status = "Not Started";

          const userMission = Object.values(
            selectedCandidateData.interventions || {}
          ).find(({ sort }) => sort === item.sort);

          if (userMission) {
            status = "Started";
            if (userMission.status) {
              status = userMission.status;
            }
          }
          newItem.status = status;

          sortedmissions.push(newItem);
        });

        sortedmissions = _.sortBy(sortedmissions, ["score"]);
      }
    }
    return (
      <div className={classes.root} ref={(el) => (this.componentRef = el)}>
        <CandidateHeader
          isInternalMobility={isInternalMobility}
          auth={this.props.auth}
          portaldetails={this.props.portaldetails}
          getBestBackpacks={getBestBackpacks}
          isOnPrintMode={this.state.isOnPrintMode}
          candidate={selectedCandidateData}
          user={selectedUserData}
          triggerReassessment={this.props.triggerReassessment}
          onMatchClick={() =>
            this.setState({ activeTab: "Jobs & Occupations" })
          }
          onJobClick={(v) =>
            this.setState({ job_match_type: v })
          }
        />
        <TabControl
          defaultTab={this.state.activeTab}
          onTabChange={() => this.setState({ activeTab: null })}
          panels={[
            {
              label: "Profile & Progress",
              render: () => (
                <CandidateSummaryView
                  getSkillsInfo={getSkillsInfo}
                  isOnPrintMode={this.state.isOnPrintMode}
                  candidate={selectedCandidateData}
                  user={selectedUserData}
                  rewards={this.props.rewards}
                  triggerReassessment={this.props.triggerReassessment}
                  addNewSkill={addNewSkill}
                  editSkillLevel={editSkillLevel}
                  saveSkills={saveSkills}
                  createValidationRequest={createValidationRequest}
                />
              ),
            },
            {
              label: "Analytics",
              render: () => (
                <CandidateEngagement
                  getCandidateEngagements={getCandidateEngagements}
                  missions={sortedmissions}
                  portaldetails={this.props.portaldetails}
                  candidate={selectedCandidateData}
                />
              ),
            },
            {
              label: "Jobs & Occupations",
              render: () => (
                <JobResults candidate={this.props.selectedUserData.userID} job_match_type={this.state.job_match_type} set_job_match_type={(v) => this.setState({ job_match_type: v })
                } />
              ),
            },
            {
              label: "Other Assessments",
              disabled: isInternalMobility ? true : false,
              render: () => <OtherAssessments />,
            },
            {
              label: "Matched Occupations (Coming Soon)",
              disabled: isInternalMobility ? true : false,
              render: () => <></>,
            },
            
            { 
              label: "Comparison (Coming Soon)",
              disabled: isInternalMobility ? false : true,
              render: () => <></>,
            }
          ]}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        triggerReassessment,
        getBestBackpacks,
        getSkillsInfo,
        getCandidateEngagements,
        addNewSkill,
        editSkillLevel,
        saveSkills,
        createValidationRequest
      },
      dispatch
    ),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CandidateSimpleDetails));
