import { Grid, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
    marginTop: 20,
    marginBottom: 10,
  },
}));

export function TabControl({ panels, defaultTab, onTabChange }) {
  const classes = useStyles();
  console.log(panels)
  const tabLabels = Array.from(new Set(panels.map(({ label }) => label))).filter((item) => !item.disabled);
  console.log(tabLabels)
  const defaultTabIndex = defaultTab
    ? tabLabels.findIndex((label) => label === defaultTab)
    : 0;
  const [currentTabIndex, changeTabIndex] = useState(defaultTabIndex);
  const [useDefault, setUseDefault] = useState(true);

  useEffect(() => {
    setUseDefault(true);
  }, [defaultTab]);

  useEffect(() => {
    defaultTab &&
      useDefault &&
      changeTabIndex(tabLabels.findIndex((label) => label === defaultTab) || 0);
  }, [defaultTab, tabLabels, useDefault]);

  function handleChange(event, newValue) {
    changeTabIndex(newValue);
    setUseDefault(false);
    //onTabChange(newValue);
  }

  return (
    <Grid>
      <Grid item>
        <Tabs
          value={currentTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={`${classes.tabsRoot} hide-on-print`}
        >
          {tabLabels.map((label) => (
            <Tab key={label} label={label} />
          ))}
        </Tabs>
      </Grid>
      <Grid item>{panels[currentTabIndex].render()}</Grid>
    </Grid>
  );
}
