import { API, Logger } from "aws-amplify";
import _ from "lodash";
import config from "../config/";
import {
  EMPTY_CANDIDATES,
  EMPTY_CANDIDATE_DETAILS,
  FETCH_CANDIDATES,
  FETCH_CANDIDATE_BACKPACK,
  FETCH_CANDIDATE_DETAILS,
  FETCH_CANDIDATE_ENGAGEMENTS,
  FETCH_CANDIDATE_JOB_MATCHES,
  FETCH_CANDIDATE_MATCHES,
  FETCH_CANDIDATE_SKILLS_INFO,
  FETCH_COINS,
  FETCH_ALL_OCCUPATIONS,
  SET_NEW_SKILLS,
  FETCH_VALIDATION_REQUESTS
} from "./types";

const logger = new Logger("CANDIDATES.ACTIONS", "INFO");

// Cognito - Auth.signIn()
export function fetchCandidates() {
  return async function (dispatch, getState) {
    var contractID = null;
    const state = getState();
    if (
      state.portaldetails.selectedContract &&
      state.portaldetails.selectedContract.contractID
    ) {
      contractID = state.portaldetails.selectedContract.contractID;
    }

    try {
      if (!contractID) {
        dispatch({ type: EMPTY_CANDIDATES, candidates: null });
        return;
      }
      let data = null;

      const currentKeyWorkerId = state.portaldetails.currentKeyWorker
        ? state.portaldetails.currentKeyWorker
        : state.portaldetails.user.userID;
      data = await API.get(
        config.API_NAME,
        `/keyworker/${currentKeyWorkerId}/candidates/${contractID}`
      );
      if (data.data && !_.isEmpty(data.data)) {
        dispatch({ type: FETCH_CANDIDATES, candidates: data.data });
      } else {
        dispatch({ type: FETCH_CANDIDATES, candidates: [] });
      }
    } catch (e) {
      dispatch({ type: FETCH_CANDIDATES, candidates: [] });
    }
  };
}

export function fetchCandidatesIM(lineManagerID, departmentID) {
  return async function (dispatch, getState) {
    try {
      const data = await API.post(config.API_NAME, "/keyworker/candidates/getIM",
        {
          body: {
            lineManagerID: lineManagerID,
            departmentID: departmentID
          },
        }
      );
      if (data.data && !_.isEmpty(data.data)) {
        dispatch({ type: FETCH_CANDIDATES, candidates: data.data });
      } else {
        dispatch({ type: FETCH_CANDIDATES, candidates: [] });
      }
    } catch (e) {
      dispatch({ type: FETCH_CANDIDATES, candidates: [] });
    }
  };
}

export function fetchCandidateDetails(candidate, keyworkerId) {
  return async function (dispatch, getState) {
    const state = getState();
    dispatch({
      type: EMPTY_CANDIDATE_DETAILS,
      selectedCandidate: null,
    });

    try {
      if (candidate) {
        let currentKeyWorkerId = state.portaldetails.currentKeyWorker
          ? state.portaldetails.currentKeyWorker
          : state.portaldetails.user.userID;

        if (keyworkerId){
          currentKeyWorkerId = keyworkerId;
        }
        let data = await API.get(
          config.API_NAME,
          `/keyworker/${currentKeyWorkerId}/candidate/${candidate.userID}`
        );
      
        if (data.data) {
          await dispatch({
            type: FETCH_CANDIDATE_DETAILS,
            selectedCandidate: data.data,
          });
          dispatch(fetchValidationRequests());
          const { candidates: { selectedCandidateData  } } = getState();

          let {
            latestAssessmentResult,
            desiredExp,
            desiredOccupations,
            occupationsExtended,
            desiredOccupationsExtended,
            occupations,
            qualifications,
            skills_names,
            occupationsOriginal,
            desiredOccupationsOriginal,
            userID,
            coinsObject} = selectedCandidateData;
            
          let payload = {
            latestAssessmentResult,
            desiredOccupations,
            occupationsExtended,
            occupations,
            desiredExp,
            qualifications,
            calculateQualifications: false,
            skills_names,
            userID,
            occupationsOriginal,
            desiredOccupationsOriginal,
          };

          const new_jobs_cats = await API.post(
            config.API_NAME,
            "/jobs/query/all_occupations",
            {
              body: {
                data: payload,
              },
            }
          );
  
          dispatch({
            type: FETCH_ALL_OCCUPATIONS,
            new_jobs_cats
          })

          dispatch({
            type: FETCH_COINS,
            data: coinsObject
          });
        } else {
          dispatch({ type: FETCH_CANDIDATE_DETAILS, selectedCandidate: {} });
        }
      }
    } catch (e) {
      logger.error("fetchCandidateDetails():", e);
      dispatch({ type: FETCH_CANDIDATE_DETAILS, selectedCandidate: {} });
    }
  };
}

export const inviteToVacancy = (userId, vacancy) => {
  let body = {
    userId:  userId,
    jobId: vacancy.jobId,
    jobTitle: vacancy.jobTitle,
    employer: vacancy.employerName,
    location : vacancy.original_location,
    salary : vacancy.annual_salary_min.toString(),
    invited : true ,
    rangeKey : vacancy.rangeKey
  };
  return async (dispatch) => {
    try {
      /*eslint-disable */
      let data = await API.post(config.API_NAME, "/keyworker/invite/candidate/", {
        body: body
      });

      let saveItem = await API.post(config.API_NAME, "/keyworker/invite/candidate/saved/", {
        body: {
          userID: userId,
          rangeKey : vacancy.rangeKey,
          jobitem : vacancy
        }
      });
      /*eslint-enable */
    } catch (e) {
      logger.error("inviteToVacancy():", e);
    }
  }
}

export function triggerReassessment(candidate) {
  return async function (dispatch, getState) {
    try {
      await dispatch(fetchCandidateDetails(candidate, true));
      const {
        candidates: { selectedCandidateData },
      } = getState();
      if (!selectedCandidateData.isInterventionOngoing) {
        let data = await API.get(
          config.API_NAME,
          "/keyworker/candidate/" + candidate.userID + "/reassessment"
        );

        if (data.data) {
          //if got data, then refresh candidate details
          dispatch(fetchCandidateDetails(candidate, true));
        }
      }
    } catch (e) {
      logger.error("triggerReassessment():", e);
    }
  };
}

export const getBestBackpacks = () => async (dispatch, getState) => {
  try {
    const {
      candidates: {
        selectedCandidateData: {
          occupations,
          desiredExp,
          desiredOccupations,
          qualifications,
          skills_names
        },
      },
    } = getState();
    
    if ( 
      !occupations &&
      !desiredOccupations &&
      !qualifications &&
      !skills_names){
      return false;
    }

    const { jobs: bestBackpack } = await API.post(
      config.API_NAME,
      "/jobs/query/skills_backpack_occupations",
      {
        body: {
          data: {
            occupations,
            desiredExp,
            desiredOccupations,
            qualifications,
            skills_names
          },
        },
      }
    );

    dispatch({ type: FETCH_CANDIDATE_BACKPACK, bestBackpack });

    // setBestBackpack(bestBackpack);
  } catch (e) {
    console.log("error", e);
  }
};

export const getMatches = (type = "interest") => async (dispatch, getState) => {
  try {
    const {
      candidates: {
        selectedCandidateData: {
          latestAssessmentResult,
          occupationsExtended,
          occupations,
          desiredExp,
          desiredOccupations,
          qualifications,
          skills_names
        },
      },
    } = getState();
    const url =
      type === "interest"
        ? `/jobs/query/interest_jobs_query`
        : `/jobs/query/styles_jobs_query`;
        
    // no data to make these calls.
    if (!latestAssessmentResult && 
      !occupationsExtended && 
      !occupations &&
      !desiredOccupations &&
      !qualifications &&
      !skills_names){
      return false;
    }
    const { jobs: matches } = await API.post(config.API_NAME, url, {
      body: {
        data: {
          occupations,
          latestAssessmentResult,
          occupationsExtended,
          desiredOccupations,
          desiredExp,
          qualifications,
          skills_names
        },
      },
    });

    dispatch({ type: FETCH_CANDIDATE_MATCHES, payload: { matches, type } });

    // setBestBackpack(bestBackpack);
  } catch (e) {
    console.log("error", e);
  }
};

export const getSkillsInfo = () => async (dispatch, getState) => {
  try {
    const {
      candidates: {
        selectedCandidateData: { occupations, desiredOccupations, occupationSkills } = {},
        skilValidationRequests = {}
        },
      } = getState();
      const { requests= [], completed= []} = skilValidationRequests;

    if (!desiredOccupations && !occupations) {
      return false;
    }
    
    let payload = {
      skills: []
    };      
    occupationSkills.forEach((skill, i) => {
      payload.skills.push(...skill.skills.map((individual_skill) => individual_skill.name))
      payload.skills.push(...skill.optionalSkills.map((individual_skill) => individual_skill.name))
      if(skill.deletedSkills){
        payload.skills.push(...skill.deletedSkills.map((individual_skill) => individual_skill.name))
      }
    })
    const { data } = await API.post(
      config.API_NAME,
      "/data/skills/description",
      {
        body: payload
      }
    );
       
    function getDesc(skill_name){
      const f = data[skill_name.toLowerCase()];
      if (f) {
        return f;
      }
      else{
        return "No description available"
      }
    }
    function skillLoop(skill_list){
      return skill_list.map((individual_skill_object) => ({
        skill:individual_skill_object.name,
        proficiency: individual_skill_object.level,
        projectedLevel: individual_skill_object.projectedLevel,
        description: getDesc(individual_skill_object.name),
        pendingValidationRequest: requests.find((item) => item.skill === individual_skill_object.name) ? true : false,
      }))
    }

    let skillsInfo = occupationSkills.map((skillObj) => {
      const skills = skillLoop(skillObj.skills)
      const optionalSkills = skillObj.optionalSkills ? skillLoop(skillObj.optionalSkills) : []
      const deletedSkills = skillObj.deletedSkills ? skillLoop(skillObj.deletedSkills) :  []
      return {
        title: skillObj['occupation'],
        skills,
        optionalSkills,
        deletedSkills
      };
    });

    skillsInfo.push(...skillsInfo.splice(skillsInfo.findIndex(obj => (obj.title.includes("Manually Added") || obj.title.includes("Resource Added Skills"))), 1))
    
    dispatch({ type: FETCH_CANDIDATE_SKILLS_INFO, skillsInfo });
  } catch (e) {
    console.log("error", e);
  }
};

export const getCandidateEngagements = () => async (dispatch, getState) => {

  try {
    const {
      auth: { role },
      portaldetails: {
        currentKeyWorker,
        user: { userID },
      },
      candidates: {
        selectedCandidateData: { userID: candidateUserId },
      },
    } = getState();

    const keyworkerId = role === "org-admin" ? currentKeyWorker : userID;


    const {
      data: {
        engagement,
        jobsApplied,
        jobsSaved,
        jobsViewed,
        quals = {},
        jobs = {},
        qualsApplied,
        qualsSaved,
        qualsViewed,
        resourceViewed,
        coursesViewed
      },
    } = await API.get(
      config.API_NAME,
      `/keyworker/${keyworkerId}/candidate/${candidateUserId}/engagement`
    );


    //TODO: move this to reducer
    if (engagement) {

      const dates = Object.keys(engagement).filter((key) =>
        key.match(/\d{4}-\d{1,2}-\d{1,2}/gm)
      );
      const engagementData = dates.map((dateString) => {
        const [year, month, date] = dateString.split("-");
        return {
          date: new Date(year, month - 1, date).toISOString(),
          duration: Math.round(engagement[dateString] / 60),
        };
      });
      engagementData.sort((a, b) => new Date(a.date) - new Date(b.date));

      const totalDuration = dates.reduce(
        (acl, curr) => acl + engagement[curr],
        0
      );

      const xmins = Math.floor(totalDuration / 60);
      const xhours = Math.floor(xmins / 60);

      var totalDurationFormatted =
        String(xhours) + " hours " + String(xmins - xhours * 60) + " minutes";

      if (xmins <= 60) {
        totalDurationFormatted = String(xmins) + " minutes";
      }

      dispatch({
        type: FETCH_CANDIDATE_ENGAGEMENTS,
        engagements: {
          chartData: engagementData,
          numberOfSessions: engagement.numberOfSessions,
          averageDurationSession: dates.length
            ? Math.round(totalDuration / 60 / engagement.numberOfSessions) +
              " mins"
            : "Not started",
          averageDurationDay: dates.length
            ? Math.round(totalDuration / 60 / dates.length) + " mins"
            : "Not started",
          totalDuration,
          totalDurationFormatted,
        },
        jobsViewed,
        jobsApplied,
        jobsSaved,
        qualsApplied,
        qualsSaved,
        qualsViewed,
        quals,
        jobs,
        resourceViewed,
        coursesViewed
      });
    } else {
      dispatch({
        type: FETCH_CANDIDATE_ENGAGEMENTS,
        engagements: null,
        jobsViewed,
        jobsApplied,
        jobsSaved,
        qualsApplied,
        qualsSaved,
        qualsViewed,
        resourceViewed: [],
        coursesViewed: []
      });
    }

    // setBestBackpack(bestBackpack);
  } catch (e) {
    console.log("error", e);
  }
};

export const getCandidateJobMatches = () => async (dispatch, getState) => {
  try {
    const {
      candidates: {
        selectedCandidateData: {
          latestAssessmentResult,
          occupations,
          occupationsExtended,
          qualifications,
        },
      },
    } = getState();
    const {
      jobs: {
        sb_tech_backpack: [backpack],
        tech_int_styles: {
          Interests: [interestOne, interestTwo],
          Styles: [stylesOne, stylesTwo],
        },
        sb_backpack: [normalBackpack],
        normal_int_styles: {
          added_occs_styles: [normalStyleOne, normalStyleTwo],
          added_occs_interests: [normalInterestOne, normalInterestTwo],
        },
      },
    } = await API.post(config.API_NAME, "/jobs/int_styles", {
      body: {
        data: {
          latestAssessmentResult,
          occupations,
          htmlStrip: true,
          occupationsExtended,
          qualifications,
        },
      },
    });

    const techJobMatches = []
      .concat({
        url: backpack.url,
        sector: backpack.sector,
        interestScore: backpack.interests_score,
        stylesScore: backpack.styles_score,
        title: backpack.most_similar_occ,
        interests: backpack.interests_normal,
        styles: backpack.styles_normal,
        skills: backpack.similarity_in_skills,
        skillScore: backpack.similarity,
      })
      .concat(
        [interestOne, interestTwo, stylesOne, stylesTwo].map(
          ([
            title,
            stylesScore,
            interestScore,
            skillScore,
            sector,
            url,
            interests,
            styles,
            skills,
          ]) => ({
            url,
            sector,
            skillScore,
            interestScore,
            stylesScore,
            title,
            interests,
            styles,
            skills,
          })
        )
      );

    const normalJobMatches = []
      .concat({
        interestScore: normalBackpack.interests_score,
        stylesScore: normalBackpack.styles_score,
        title: normalBackpack.most_similar_occ,
        interests: normalBackpack.interests_normal,
        styles: normalBackpack.styles_normal,
        skills: normalBackpack.similarity_in_skills,
        skillScore: normalBackpack.similarity,
      })
      .concat(
        [
          normalInterestOne,
          normalInterestTwo,
          normalStyleOne,
          normalStyleTwo,
        ].map(
          ([
            title,
            stylesScore,
            interestScore,
            skillScore,
            interests,
            styles,
            skills = [],
          ]) => ({
            skillScore,
            interestScore,
            stylesScore,
            title,
            interests,
            styles,
            skills,
          })
        )
      );

    dispatch({
      type: FETCH_CANDIDATE_JOB_MATCHES,
      payload: { techJobMatches, normalJobMatches },
    });

    // setBestBackpack(bestBackpack);
  } catch (e) {
    console.log("error", e);
  }
};

export function addNewSkill(skills) {
  return async function (dispatch, getState) {
    try {
      const { candidates: { selectedCandidateData = {} } } = getState();
      const { userID, occupationSkills=[] } = selectedCandidateData;
      let newOccupationSkills = occupationSkills
      let skillObjIdx = null
      let newSkillList = skills.map((item) => {return {"name": item, "level": 1}})

      occupationSkills.forEach(function (item, index) {
        if (item.occupation === "Manually Added: Skills"){
          skillObjIdx = index
        }
      });
      
      if(skillObjIdx){
        newOccupationSkills[skillObjIdx].skills.push(...newSkillList)
      }
      else{
        skillObjIdx = newOccupationSkills.length
        newOccupationSkills.push({'occupation': "Manually Added: Skills", 'expertise': "Beginner", 'skills': newSkillList, 'optionalSkills': [], 'deletedSkills':[]})
      }


      const data = await API.post(
        config.API_NAME,
        "/keyworker/candidateskills",
        {
          body: {
            userID: userID,
            occupationSkills: newOccupationSkills
          },
        }
      );
      await dispatch({
        type: SET_NEW_SKILLS,
        newOccupationSkills: newOccupationSkills,
      });
      await dispatch(getSkillsInfo());

    } catch (e) {
      logger.error("addNewSkill():", e);
    }
  }
}

export function editSkillLevel(skill, level) {
  return async function (dispatch, getState) {
    try {
      const { candidates: { selectedCandidateData = {} } } = getState();
      const { occupationSkills=[], skillsInfo=[] } = selectedCandidateData;
      function skillLoop(skill_list){
        return skill_list.map((_item) => {
          if(_item.skill === skill || _item.name === skill){
            return {..._item, projectedLevel: level}
          }
          else{
            return _item
          }
        })
      }

      const newSkillsInfo = skillsInfo.map((item) => {
        return {...item, skills: skillLoop(item.skills), optionalSkills: item.optionalSkills ? skillLoop(item.optionalSkills) : [], deletedSkills: item.deletedSkills ? skillLoop(item.deletedSkills) : []}
      })

      const newOccupationSkills = occupationSkills.map((item) => {
        return {...item, skills: skillLoop(item.skills), optionalSkills: item.optionalSkills ? skillLoop(item.optionalSkills) : [], deletedSkills: item.deletedSkills ? skillLoop(item.deletedSkills) : []}
      })

      console.log(newOccupationSkills)
      console.log(newSkillsInfo)

      await dispatch({type: SET_NEW_SKILLS, newOccupationSkills: newOccupationSkills});
      dispatch({ type: FETCH_CANDIDATE_SKILLS_INFO, skillsInfo: newSkillsInfo });

    } catch (e) {
      logger.error("editSkillLevel():", e);
    }
  }
}


export function saveSkills(skills) {
  return async function (dispatch, getState) {
    try {
      const { candidates: { selectedCandidateData = {} } } = getState();
      const { userID, occupationSkills=[] } = selectedCandidateData;

      const data = await API.post(
        config.API_NAME,
        "/keyworker/candidateskills",
        {
          body: {
            userID: userID,
            occupationSkills: occupationSkills
          },
        }
      );

      dispatch(setSkillUpdates(skills))

    } catch (e) {
      logger.error("saveSkills():", e);
    }
  }
}


export function fetchValidationRequests() {
  return async function (dispatch, getState) {
    try {
      const { candidates: { selectedCandidateData = {} } } = getState();
      const { userID } = selectedCandidateData;

      const data = await API.post(
        config.API_NAME,
        "/keyworker/validationrequests/get",
        {
          body: {
            userID: userID
          },
        }
      );
      if(data && data.success){
        dispatch({ type: FETCH_VALIDATION_REQUESTS, data: data.data });
      }
      else{
        dispatch({ type: FETCH_VALIDATION_REQUESTS, data: []});
      }
    } catch (e) {
      logger.error("fetchValidationRequests():", e);
    }
  }
}

export function createValidationRequest(skill) {
  return async function (dispatch, getState) {
    try {
      const { candidates: { selectedCandidateData = {} } } = getState();
      const { userID, skillsInfo } = selectedCandidateData;

      const data = await API.post(
        config.API_NAME,
        "/keyworker/validationrequests/set",
        {
          body: {
            userID: userID,
            skill: skill
          },
        }
      );
      if(data && data.success){
        dispatch({ type: FETCH_VALIDATION_REQUESTS, data: data.data });

        function skillLoop(skill_list){
          return skill_list.map((individual_skill_object) => ({
            ...individual_skill_object,
            pendingValidationRequest: data.data.requests.find((item) => item.skill === individual_skill_object.skill) ? true : false,
          }))
        }

        let newSkillsInfo = skillsInfo.map((skillObj) => {
          const skills = skillLoop(skillObj.skills)
          const optionalSkills = skillObj.optionalSkills ? skillLoop(skillObj.optionalSkills) : []
          const deletedSkills = skillObj.deletedSkills ? skillLoop(skillObj.deletedSkills) :  []
          return {
            ...skillObj,
            skills: skills,
            optionalSkills: optionalSkills,
            deletedSkills: deletedSkills
          };
        });
        console.log(newSkillsInfo)
        dispatch({ type: FETCH_CANDIDATE_SKILLS_INFO, skillsInfo:newSkillsInfo });

      }
      else{
        dispatch({ type: FETCH_VALIDATION_REQUESTS, data: {}});
      }

    } catch (e) {
      logger.error("createValidationRequest():", e);
    }
  }
}

export function setSkillUpdates(skills) {
  return async function (dispatch, getState) {
    try {
      const { candidates: { selectedCandidateData = {} } } = getState();
      const { userID } = selectedCandidateData;

      const data = await API.post(
        config.API_NAME,
        "/keyworker/projectedskills/set",
        {
          body: {
            userID: userID,
            skills: skills
          },
        }
      );
    } catch (e) {
      logger.error("setSkillUpdates():", e);
    }
  }
}